var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import styled from "styled-components";
import { Switch, Button, Toast, Toaster, ToastTitle, useId, useToastController, } from "@fluentui/react-components";
import { MyAreaService } from "./MyAreaService";
import { ColorPicker } from "@fluentui/react";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-ljb0qd" }) `
  margin-inline-end: 3rem;
  font-family: "Segoe UI";

  > h1 {
    color: var(--NuetralPrimary, #3d3a3b);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  > h4 {
    color: var(--NuetralPrimary, #3d3a3b);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0px;
  }

  > h3 {
    color: var(--NuetralPrimary, #3d3a3b);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    padding-top: 32px;
    padding-bottom: 16px;
  }
`;
const Group = styled.div.withConfig({ displayName: "Group", componentId: "-jitwth" }) `
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  background-color: #fbfbfb;
  width: 610px;
  min-height: 36px;
  align-items: center;
`;
const GroupHeader = styled.div.withConfig({ displayName: "GroupHeader", componentId: "-oouws8" }) `
  padding-left: 10px;
`;
const Hr = styled.div.withConfig({ displayName: "Hr", componentId: "-8429vg" }) `
  position: relative;
  border-bottom: 2px solid #f2f2f2;
  left: 2%;
  width: 96%;
`;
const SwitchLabel = styled.label.withConfig({ displayName: "SwitchLabel", componentId: "-80x54e" }) `
  font-weight: 600;
  color: #3d3a3b;
  font-size: 16px;
`;
const SectionContainer = styled.div.withConfig({ displayName: "SectionContainer", componentId: "-1v375rz" }) `
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 12px;
  min-height: 56px;
  align-items: center;
`;
const ColorPickerRow = styled.div.withConfig({ displayName: "ColorPickerRow", componentId: "-19fyr7k" }) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  padding-top: 0px;
  align-items: center;
`;
const PickerToggle = styled.div.withConfig({ displayName: "PickerToggle", componentId: "-1n0b4g4" }) `
  position: relative;
  width: 32px;
  height: 32px;
  background: conic-gradient(
    from 90deg,
    violet,
    indigo,
    blue,
    green,
    yellow,
    orange,
    red,
    violet
  );
  display: inline-block;
  border-radius: 50%;
  margin-block: 0 10px;
  cursor: pointer;
`;
const PickerToggleInner = styled.div.withConfig({ displayName: "PickerToggleInner", componentId: "-dhz085" }) `
  position: absolute;
  inset: 4px;
  border-radius: 50%;
  border: 2px solid white;
`;
const ColorLabel = styled.label.withConfig({ displayName: "ColorLabel", componentId: "-1q5fp1v" }) `
  font-weight: 600;
  color: #3d3a3b;
  font-size: 16px;
`;
const ColorSelector = styled(ColorPicker).withConfig({ displayName: "ColorSelector", componentId: "-1gdittb" }) `
  padding-top: 0px;
`;
const ColorPickerContainer = styled.div.withConfig({ displayName: "ColorPickerContainer", componentId: "-ca5v3" }) `
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #fbfbfb;
  margin-left: 310px;
  z-index: 2;
  border: 2px solid #f7f7f7;
  border-radius: 12px;

  .ms-ColorPicker-panel {
    padding: 14px 14px 20px;
  }

  .ms-TextField-fieldGroup {
    border-color: #e5e8e8;
    border-radius: 8px;
  }

  tr {
    height: 30px;
    font-weight: 600;
  }

  .mode-edit & {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.025),
      0 4px 4px rgba(0, 0, 0, 0.025), 0 8px 8px rgba(0, 0, 0, 0.025);
  }
`;
const MyArea = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [myArea, setMyArea] = useState(null);
    const [general, setGeneral] = useState(null);
    const [sessions, setSessions] = useState(null);
    const [displayPicker, setDisplayPicker] = React.useState(false);
    const [titleColor, setTitleColor] = React.useState({
        str: "#3D3A3B",
    });
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    useEffect(() => {
        setIsLoading(true);
        MyAreaService.GetMyArea().then((response) => {
            setMyArea(response);
            setIsLoading(false);
        });
    }, []);
    useEffect(() => {
        setGeneral(myArea === null || myArea === void 0 ? void 0 : myArea.general);
        setSessions(myArea === null || myArea === void 0 ? void 0 : myArea.sessions);
    }, [myArea]);
    useEffect(() => {
        setTitleColor({ str: general === null || general === void 0 ? void 0 : general.titleColor });
    }, [general, sessions]);
    const handleUseBrandingPrimaryColorChange = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            setMyArea(yield MyAreaService.SaveGeneral(Object.assign(Object.assign({}, general), { useBrandingsPrimaryColor: !(general === null || general === void 0 ? void 0 : general.useBrandingsPrimaryColor) })));
            setIsLoading(false);
            notify("The use of Branding's primary color was updated successfully");
        }
        catch (e) {
            setIsLoading(false);
        }
    });
    const updateTitleColor = React.useCallback((ev, colorObj) => setTitleColor(colorObj), []);
    const handleTitleColorUpdateClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            setDisplayPicker(!displayPicker);
            setMyArea(yield MyAreaService.SaveGeneral(Object.assign(Object.assign({}, general), { titleColor: titleColor.str })));
            setIsLoading(false);
            notify("Title color setting have been applied successfully!");
        }
        catch (e) {
            setIsLoading(false);
        }
    });
    const enableQuicklinksSessionChanged = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            setMyArea(yield MyAreaService.SaveSession(Object.assign(Object.assign({}, sessions), { enableQuicklinksSession: !(sessions === null || sessions === void 0 ? void 0 : sessions.enableQuicklinksSession) })));
            setIsLoading(false);
            notify("Quicklinks session setting have been applied successfully!");
        }
        catch (e) {
            setIsLoading(false);
        }
    });
    const enableDocumentsSessionChanged = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            setMyArea(yield MyAreaService.SaveSession(Object.assign(Object.assign({}, sessions), { enableDocumentsSession: !(sessions === null || sessions === void 0 ? void 0 : sessions.enableDocumentsSession) })));
            setIsLoading(false);
            notify("Documents session setting have been applied successfully!");
        }
        catch (e) {
            setIsLoading(false);
        }
    });
    const enablePoliciesSessionChanged = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            setMyArea(yield MyAreaService.SaveSession(Object.assign(Object.assign({}, sessions), { enablePoliciesSession: !(sessions === null || sessions === void 0 ? void 0 : sessions.enablePoliciesSession) })));
            setIsLoading(false);
            notify("Policies session setting have been applied successfully!");
        }
        catch (e) {
            setIsLoading(false);
        }
    });
    const notify = (message) => dispatchToast(_jsx(Toast, { children: _jsx(ToastTitle, { children: message }) }), { intent: "success" });
    return (_jsxs(Container, { children: [_jsx("h1", { children: "My Area" }), _jsx("h4", { children: "Customize the user's editing permissions." }), isLoading ? (_jsx(Spinner, { size: SpinnerSize.medium, label: "We're getting everything ready for you...", ariaLive: "assertive", labelPosition: "right" })) : (_jsxs(_Fragment, { children: [_jsx("h3", { children: "General" }), _jsxs(Group, { children: [_jsx(GroupHeader, { children: _jsx("h4", { children: "Welcome title color" }) }), _jsxs(SectionContainer, { children: [_jsx(SwitchLabel, { children: "Use branding's primary color" }), _jsx(Switch, { checked: general === null || general === void 0 ? void 0 : general.useBrandingsPrimaryColor, onClick: (e) => {
                                            e.stopPropagation();
                                            handleUseBrandingPrimaryColorChange();
                                        } })] }), !(general === null || general === void 0 ? void 0 : general.useBrandingsPrimaryColor) && (_jsxs(_Fragment, { children: [_jsxs(ColorPickerRow, { children: [_jsx(ColorLabel, { children: "Title color" }), _jsx(PickerToggle, Object.assign({ onClick: () => setDisplayPicker(!displayPicker) }, { children: _jsx(PickerToggleInner, { style: { background: titleColor.str } }) }))] }), displayPicker && (_jsxs(ColorPickerContainer, { children: [_jsx(ColorSelector, { color: titleColor.str, alphaType: "none", onChange: updateTitleColor }), _jsx(Button, Object.assign({ appearance: "primary", style: { margin: "15px" }, onClick: handleTitleColorUpdateClicked }, { children: "Apply color" }))] }))] }))] }), _jsx("h3", { children: "Sessions" }), _jsxs(Group, { children: [_jsxs(SectionContainer, { children: [_jsx(SwitchLabel, { children: "Enable Quick links session" }), _jsx(Switch, { checked: sessions === null || sessions === void 0 ? void 0 : sessions.enableQuicklinksSession, onClick: (e) => {
                                            e.stopPropagation();
                                            enableQuicklinksSessionChanged();
                                        } })] }), _jsx(Hr, {}), _jsxs(SectionContainer, { children: [_jsx(SwitchLabel, { children: "Enable Documents session" }), _jsx(Switch, { checked: sessions === null || sessions === void 0 ? void 0 : sessions.enableDocumentsSession, onClick: (e) => {
                                            e.stopPropagation();
                                            enableDocumentsSessionChanged();
                                        } })] }), _jsx(Hr, {}), _jsxs(SectionContainer, { children: [_jsx(SwitchLabel, { children: "Enable Policies session" }), _jsx(Switch, { checked: sessions === null || sessions === void 0 ? void 0 : sessions.enablePoliciesSession, onClick: (e) => {
                                            e.stopPropagation();
                                            enablePoliciesSessionChanged();
                                        } })] })] })] })), _jsx(Toaster, { toasterId: toasterId, position: "top-end", mountNode: undefined })] }));
};
export default MyArea;
