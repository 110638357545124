import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
export const TopMenuContainer = styled.div.withConfig({ displayName: "TopMenuContainer", componentId: "-1a4ks6f" }) `
  grid-area: sidebar;
  background: var(--sidebar-bg);
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
`;
export const TopMenuItems = styled.div.withConfig({ displayName: "TopMenuItems", componentId: "-12bdsze" }) `
  display: flex;
  flex-direction: row;
  color: var(--sidebar-color);
  margin-bottom: 1rem;
`;
export const TopMenuItem = styled(Link).withConfig({ displayName: "TopMenuItem", componentId: "-14yrb2p" }) `
  display: inline-flex;
  padding: 10px var(--default-gutter);
  cursor: pointer;
  color: #5c5653;
  text-decoration: none;
  &:hover {
    background: var(--sidebar-bg-highlight);
  }

  &.active {
    background: var(--sidebar-bg-highlight);
    font-weight: 600;
    border-bottom: 4px solid #512da8;
  }

  &:first-of-type {
    border-top: 0;
  }
  & > svg {
    margin-right: 10px;
    margin-top: 2px;
  }
`;
export const TopMenu = (props) => {
    const [activeItem, setActiveItem] = useState(props.activeItem || 1);
    const routeParams = useParams();
    console.log("routeParams", routeParams);
    const handleItemClick = (index) => {
        setActiveItem(index);
        props.setView(index);
    };
    return (_jsx(_Fragment, { children: _jsxs(TopMenuItems, { children: [_jsx(TopMenuItem, Object.assign({ to: `/intranet/navigation/megamenu/${routeParams.menuId}/content`, className: `${activeItem === 1 ? "active" : ""}`, onClick: () => handleItemClick(1) }, { children: "Content" })), _jsx(TopMenuItem, Object.assign({ to: `/intranet/navigation/megamenu/${routeParams.menuId}/styling`, className: `${activeItem === 2 ? "active" : ""}`, onClick: () => handleItemClick(2) }, { children: "Styling" }))] }) }));
};
export default TopMenu;
