var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { instance } from "../../../../index";
import { draftStatus } from "../constants";
import envVarConfig from "../../../../env/envVarConfig";
import jwtDecode from "jwt-decode";
const apiPath = envVarConfig.reactApiUrl;
export class MegaMenuAPIService {
    static getToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const accounts = instance.getAllAccounts();
            const token = yield instance.acquireTokenSilent({
                account: accounts[0],
                scopes: [envVarConfig.backOfficeAppScope],
            });
            return token.accessToken;
        });
    }
    static getSiteCollection(clientRegion) {
        return __awaiter(this, void 0, void 0, function* () {
            const userToken = yield MegaMenuAPIService.getToken();
            try {
                const response = yield fetch(apiPath + "/Graph/sites", {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "X-Region": clientRegion,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                });
                return response.json();
            }
            catch (error) {
                console.log("Error retrieving site collection:", error);
                throw error;
            }
        });
    }
    static GetMatrixNavigation(tenantId, clientRegion) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const userToken = yield MegaMenuAPIService.getToken();
                const response = yield fetch(apiPath + "/MatrixNavigation" + "/" + tenantId, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "X-Region": clientRegion,
                        "Access-Control-Allow-Origin": "*",
                    },
                });
                return response;
            }
            catch (error) {
                console.log("Error retrieving menu:", error);
                throw error;
            }
        });
    }
    static getMenu(tenantId, clientRegion) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const userToken = yield MegaMenuAPIService.getToken();
                const response = yield fetch(apiPath + "/MegaMenu" + "/" + tenantId, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "X-Region": clientRegion,
                        "Access-Control-Allow-Origin": "*",
                    },
                });
                return response;
            }
            catch (error) {
                console.log("Error retrieving menu:", error);
                throw error;
            }
        });
    }
    static saveMenu(menu, clientRegion) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const userString = sessionStorage.getItem("User");
                const user = userString ? JSON.parse(userString) : null;
                const tenantId = user ? user.tenantId : "";
                const requestPayload = {
                    id: menu.id,
                    tenantId: tenantId,
                    generalOptions: {
                        matrixEnabled: menu.generalOptions.matrixEnabled,
                        hideSpAppBar: menu.generalOptions.hideSpAppBar,
                        useGlobalSearch: menu.generalOptions.useGlobalSearch,
                        useSearchBar: menu.generalOptions.useSearchBar,
                        hideBreadcrumb: menu.generalOptions.hideBreadcrumb,
                        hideSpHeader: menu.generalOptions.hideSpHeader,
                        hideSearch: menu.generalOptions.hideSearch,
                        enableProductIntegration: menu.generalOptions.enableProductIntegration,
                        logoUrl: menu.generalOptions.logoUrl,
                        primaryLanguage: menu.generalOptions.primaryLanguage,
                        otherLanguages: menu.generalOptions.otherLanguages,
                        useMegaMenuStyle: menu.generalOptions.useMegaMenuStyle,
                        customBreadcrumbEnabled: menu.generalOptions.customBreadcrumbEnabled,
                        customBreadcrumbName: menu.generalOptions.customBreadcrumbName,
                        customBreadcrumbUrl: menu.generalOptions.customBreadcrumbUrl,
                    },
                    siteCollection: menu.siteCollection,
                    clientRegion: clientRegion,
                };
                const userToken = yield MegaMenuAPIService.getToken();
                const options = {
                    body: JSON.stringify(requestPayload),
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "X-Region": clientRegion,
                    },
                };
                const response = yield fetch(apiPath + "/MegaMenu", options);
                return response;
            }
            catch (error) {
                console.log("Error saving menu:", error);
                throw error;
            }
        });
    }
    static saveMatrix(matrixMenu, clientRegion) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const userString = sessionStorage.getItem("User");
                const user = userString ? JSON.parse(userString) : null;
                const tenantId = user ? user.tenantId : "";
                const requestPayload = {
                    id: matrixMenu.id,
                    tenantId: tenantId,
                    status: matrixMenu.status !== "" ? matrixMenu.status : draftStatus,
                    dimensions: matrixMenu.dimensions,
                    paths: matrixMenu.paths,
                };
                const userToken = yield MegaMenuAPIService.getToken();
                const options = {
                    body: JSON.stringify(requestPayload),
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "X-Region": clientRegion,
                    },
                };
                const response = yield fetch(apiPath + "/MatrixNavigation", options);
                return response;
            }
            catch (error) {
                console.log("Error saving matrix:", error);
                throw error;
            }
        });
    }
    static publishMenu(menu, clientRegion) {
        return __awaiter(this, void 0, void 0, function* () {
            const userString = sessionStorage.getItem("User");
            const user = userString ? JSON.parse(userString) : null;
            const tenantId = user ? user.tenantId : "";
            const requestPayload = {
                id: menu.id,
                tenantId: tenantId,
                generalOptions: {
                    hideSpAppBar: menu.generalOptions.hideSpAppBar,
                    matrixEnabled: menu.generalOptions.matrixEnabled,
                    hideBreadcrumb: menu.generalOptions.hideBreadcrumb,
                    hideSpHeader: menu.generalOptions.hideSpHeader,
                    hideSearch: menu.generalOptions.hideSearch,
                    useGlobalSearch: menu.generalOptions.useGlobalSearch,
                    enableProductIntegration: menu.generalOptions.enableProductIntegration,
                    useSearchBar: menu.generalOptions.useSearchBar,
                    logoUrl: menu.generalOptions.logoUrl,
                    primaryLanguage: menu.generalOptions.primaryLanguage,
                    otherLanguages: menu.generalOptions.otherLanguages,
                    useMegaMenuStyle: menu.generalOptions.useMegaMenuStyle,
                    customBreadcrumbEnabled: menu.generalOptions.customBreadcrumbEnabled,
                    customBreadcrumbName: menu.generalOptions.customBreadcrumbName,
                    customBreadcrumbUrl: menu.generalOptions.customBreadcrumbUrl,
                },
                siteCollection: menu.siteCollection,
            };
            const userToken = yield MegaMenuAPIService.getToken();
            const options = {
                body: JSON.stringify(requestPayload),
                method: "POST",
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "X-Region": clientRegion,
                },
            };
            if (menu.id) {
                const response = yield fetch(apiPath + "/MegaMenu" + "/Publish", options);
                return response;
            }
            else {
                yield this.saveMenu(menu, clientRegion).then((responseSaved) => {
                    responseSaved.json().then((data) => {
                        options.body = JSON.stringify(data);
                        fetch(apiPath + "/MegaMenu" + "/Publish", options).then((response) => {
                            return response;
                        });
                    });
                });
            }
        });
    }
    static publishMatrix(matrixMenu, clientRegion) {
        return __awaiter(this, void 0, void 0, function* () {
            const userString = sessionStorage.getItem("User");
            const user = userString ? JSON.parse(userString) : null;
            const tenantId = user ? user.tenantId : "";
            const requestPayload = {
                id: matrixMenu.id,
                tenantId: tenantId,
                status: matrixMenu.status !== "" ? matrixMenu.status : draftStatus,
                dimensions: matrixMenu.dimensions,
                paths: matrixMenu.paths,
            };
            const userToken = yield MegaMenuAPIService.getToken();
            const options = {
                body: JSON.stringify(requestPayload),
                method: "POST",
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "X-Region": clientRegion,
                },
            };
            if (matrixMenu.id) {
                const response = yield fetch(apiPath + "/MatrixNavigation" + "/Publish", options);
                return response;
            }
            else {
                yield this.saveMatrix(matrixMenu, clientRegion).then((responseSaved) => {
                    responseSaved.json().then((data) => {
                        options.body = JSON.stringify(data);
                        fetch(apiPath + "/MatrixNavigation" + "/Publish", options).then((response) => {
                            return response;
                        });
                    });
                });
            }
        });
    }
    static getFilteredEntities(searchText, type = undefined) {
        return __awaiter(this, void 0, void 0, function* () {
            const userToken = yield MegaMenuAPIService.getToken();
            try {
                if (type !== undefined) {
                    const response = yield fetch(apiPath + "/Graph/entities/" + searchText + "/" + type, {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                            //"X-Region": clientRegion,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                        },
                    });
                    return response.json();
                }
                else {
                    const response = yield fetch(apiPath + "/Graph/entities/" + searchText, {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                            //"X-Region": clientRegion,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                        },
                    });
                    return response.json();
                }
            }
            catch (error) {
                console.log("Error retrieving users:", error);
                throw error;
            }
        });
    }
    static getEntity(id, type) {
        return __awaiter(this, void 0, void 0, function* () {
            const userToken = yield MegaMenuAPIService.getToken();
            try {
                const response = yield fetch(apiPath + "/Graph/entity/" + id + "/" + type, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                });
                if (response) {
                    return response.json();
                }
            }
            catch (error) {
                console.log("Error retrieving entity:", error);
                throw error;
            }
        });
    }
    static getGraphUserPhoto(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const userToken = yield MegaMenuAPIService.getToken();
            try {
                const response = yield fetch(apiPath + "/Graph/users/photo/" + id, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                });
                return response.json();
            }
            catch (error) {
                console.log("Error retrieving user photo:", error);
                throw error;
            }
        });
    }
    static getUsers() {
        return __awaiter(this, void 0, void 0, function* () {
            const userToken = yield MegaMenuAPIService.getToken();
            try {
                const response = yield fetch(apiPath + "/Graph/users", {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                });
                return response.json();
            }
            catch (error) {
                console.log("Error retrieving users:", error);
                throw error;
            }
        });
    }
    //TODO: confirm if we need to pass the client region
    static getAssignedUsers(role) {
        return __awaiter(this, void 0, void 0, function* () {
            const userToken = yield MegaMenuAPIService.getToken();
            try {
                const response = yield fetch(apiPath + "/Graph/assignedRoles/" + role, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        //"X-Region": clientRegion,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                });
                return response.json();
            }
            catch (error) {
                console.log("Error retrieving users:", error);
                throw error;
            }
        });
    }
    //TODO: confirm if we need to pass the client region
    static checkUserIsAdmin(user) {
        return __awaiter(this, void 0, void 0, function* () {
            const userToken = yield MegaMenuAPIService.getToken();
            try {
                const response = yield fetch(apiPath + "/Graph/ckeckUserIsAssigned/" + user + "/admins", {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        //"X-Region": clientRegion,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                });
                return response.json();
            }
            catch (error) {
                console.log("Error retrieving users:", error);
                throw error;
            }
        });
    }
    static getAssignedRoles() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const userToken = yield MegaMenuAPIService.getToken();
            const tokenDetails = jwtDecode(userToken);
            const isAdmin = (_a = tokenDetails === null || tokenDetails === void 0 ? void 0 : tokenDetails.roles) === null || _a === void 0 ? void 0 : _a.includes("Admins.Group");
            const isEditor = (_b = tokenDetails === null || tokenDetails === void 0 ? void 0 : tokenDetails.roles) === null || _b === void 0 ? void 0 : _b.includes("Editors.Group");
            return { isAdmin, isEditor };
        });
    }
    //TODO: confirm if we need to pass the client region
    static checkUserIsEditor(user) {
        return __awaiter(this, void 0, void 0, function* () {
            const userToken = yield MegaMenuAPIService.getToken();
            try {
                const response = yield fetch(apiPath + "/Graph/ckeckUserIsAssigned/" + user + "/editors", {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        //"X-Region": clientRegion,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                });
                return response.json();
            }
            catch (error) {
                console.log("Error retrieving users:", error);
                throw error;
            }
        });
    }
    static removeAssignedUsers(users, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const userToken = yield MegaMenuAPIService.getToken();
            try {
                const response = yield fetch(apiPath + "/Graph/assignRole/" + users + "/" + role, {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        //"X-Region": clientRegion,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                });
                return response.json();
            }
            catch (error) {
                console.log("Error retrieving users:", error);
                throw error;
            }
        });
    }
    static addAssignedUsers(users, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const userToken = yield MegaMenuAPIService.getToken();
            try {
                const response = yield fetch(apiPath + "/Graph/assignRole/" + users + "/" + role, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        //"X-Region": clientRegion,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                });
                return response.json();
            }
            catch (error) {
                console.log("Error retrieving users:", error);
                throw error;
            }
        });
    }
}
