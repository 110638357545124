import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import styled from "styled-components";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { EditingFirstLevelNode } from "./EditingFirstLevelNode/EditingFirstLevelNode";
import { EditingModal } from "./Modals/EditingModal/EditingModal";
import { EditingSecondLevel } from "./EditingSecondLevel/EditingSecondLevel";
import { EditingThirdLevel } from "./EditingThirdLevel/EditingThirdLevel";
import { EditingFourthLevel } from "./EditingFourthLevel/EditingFourthLevel";
import { DroppableContainer, levelNoticeStyle, ListWrapper, } from "./ContentEditor.styles";
import { StrictModeDroppable } from "./StrictModeDroppable";
import { Button, useId, Link as ToastLink, Toaster, useToastController, Toast, ToastTitle, ToastTrigger, } from "@fluentui/react-components";
//react drag and drop inspired from here: https://codesandbox.io/s/mmrp44okvj?file=/index.js
const ContentWrapper = styled.div.withConfig({ displayName: "ContentWrapper", componentId: "-1v3dg7o" }) `
  display: grid;
  grid-template-columns: 280px 280px 280px 280px;
  grid-gap: 1rem;
  width: 100%;
  max-height: 600px;
  padding: 1rem calc(var(--default-gutter));

  > * + * {
    border-left: 0px solid rgba(0, 0, 0, 0.15);
  }

  h3 {
    margin: 0 0 10px;
    font-weight: 700;
  }
`;
const FirstLevelWrapper = styled.div.withConfig({ displayName: "FirstLevelWrapper", componentId: "-1hs8a6i" }) `
  background-color: var(--editor-level2-bg);
  max-height: var(--flyout-max-height, 200px);
`;
const AddNewNodeWrapper = styled.div.withConfig({ displayName: "AddNewNodeWrapper", componentId: "-1hpu4a4" }) `
  display: flex;
  justify-content: right;
`;
const AddButton = styled(Button).withConfig({ displayName: "AddButton", componentId: "-1gb1qee" }) `
  && {
    min-width: 0;
    margin-top: .5rem;
  }
`;
const LevelNotice = styled.div.withConfig({ displayName: "LevelNotice", componentId: "-1nktkzv" }) `
  ${levelNoticeStyle}
`;
const DraggableWrapper = styled.div.withConfig({ displayName: "DraggableWrapper", componentId: "-17skdgl" }) `
  &:focus {
    outline: 0
  }
`;
export const ContentEditor = ({ nodes, selectedFirstNode, selectedFourthLevelNode, selectedLanguage, selectedNode, selectedSecondLevelNode, selectedThirdLevelNode, setSelectedFirstNode, setSelectedFourthLevelNode, setSelectedNode, setSelectedSecondLevelNode, setSelectedThirdLevelNode, updateNodes, }) => {
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const [showModal, setShowModal] = React.useState(false);
    const [columnsNum, setNumOfColumns] = React.useState(document.getElementById("lt-megamenu-content-wrapper") !== null
        ? Math.floor(document.getElementById("lt-megamenu-content-wrapper").clientWidth / 215)
        : Math.floor(window.innerWidth / 215));
    const [customWidth, setCustomWidth] = React.useState(window.innerWidth - 35);
    useEffect(() => {
        const resizeHandler = () => {
            if (!!document.getElementById("lt-megamenu-content-wrapper")) {
                const newColumnsNum = Math.floor(document.getElementById("lt-megamenu-content-wrapper").clientWidth / 215);
                const customWidth = document.getElementById("lt-megamenu-first-level-nodes-wrapper").clientWidth;
                setCustomWidth(customWidth);
                if (newColumnsNum === 0 || newColumnsNum === -1) {
                    setNumOfColumns(1);
                }
                else {
                    setNumOfColumns(newColumnsNum);
                }
            }
        };
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);
    function addNewNode() {
        setShowModal(true);
    }
    function deleteNode(node) {
        const newNodes = [...nodes];
        newNodes.splice(newNodes.indexOf(node), 1);
        setSelectedFirstNode(null);
        setSelectedSecondLevelNode(null);
        setSelectedThirdLevelNode(null);
        updateNodes(newNodes);
    }
    function updateNode(newNode, oldNode) {
        const newNodes = [...nodes];
        newNodes[newNodes.indexOf(oldNode)] = newNode;
        updateNodes(newNodes);
        setSelectedFirstNode(selectedFirstNode);
        setSelectedNode(newNode);
    }
    function addNode(node) {
        const newNodes = [...nodes];
        newNodes.push(node);
        updateNodes(newNodes);
        setShowModal(false);
        setSelectedFirstNode(node);
        setSelectedSecondLevelNode(null);
        setSelectedThirdLevelNode(null);
    }
    function updateSecondLevelNodes(parentNode, nodeList) {
        const newNodes = [...nodes];
        const nodeToUpdate = newNodes[newNodes.indexOf(parentNode)];
        nodeToUpdate.nodes = nodeList;
        updateNodes(newNodes);
    }
    function updateThirdLevelNodes(secondLevelParentNode, nodeList) {
        const newNodes = [...nodes];
        //we find the first level node that is selected
        const firstLevelNode = newNodes[newNodes.indexOf(selectedFirstNode)];
        // we find the second level node that is to be updated and update the 3rd level nodes
        const secondLevelNodes = firstLevelNode.nodes;
        const secondLevelNodeToUpdate = secondLevelNodes[secondLevelNodes.indexOf(secondLevelParentNode)];
        secondLevelNodeToUpdate.nodes = nodeList;
        updateNodes(newNodes);
    }
    function updateFourthLevelNodes(thirdLevelParentNode, nodeList) {
        const newNodes = [...nodes];
        //we find the first level node that is selected
        const firstLevelNode = newNodes[newNodes.indexOf(selectedFirstNode)];
        const secondLevelNodes = firstLevelNode.nodes;
        const secondLevelNodeToUpdate = secondLevelNodes[secondLevelNodes.indexOf(selectedSecondLevelNode)];
        const thirdLevelNodes = secondLevelNodeToUpdate.nodes;
        const thirdLevelNodeToUpdate = thirdLevelNodes[thirdLevelNodes.indexOf(thirdLevelParentNode)];
        thirdLevelNodeToUpdate.nodes = nodeList;
        updateNodes(newNodes);
    }
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const newNodes = reorder(nodes, result.source.index, result.destination.index);
        updateNodes(newNodes);
    }
    const getItemStyle = (isDragging, draggableStyle) => (Object.assign({ userSelect: "none", background: isDragging ? "inhreit" : "none" }, draggableStyle));
    const notify = (message, type = "success", dismissable = false, timeout = 3000) => dispatchToast(_jsx(Toast, { children: _jsx(ToastTitle, Object.assign({ action: dismissable ?
                _jsx(ToastTrigger, { children: _jsx(ToastLink, { children: "Dismiss" }) })
                : null }, { children: message })) }), { timeout, intent: type });
    return (_jsxs(ContentWrapper, Object.assign({ id: "lt-megamenu-content-wrapper" }, { children: [_jsx(DragDropContext, Object.assign({ onDragEnd: onDragEnd }, { children: _jsxs(FirstLevelWrapper, Object.assign({ id: "lt-megamenu-first-level-nodes-wrapper", className: "activeLevel" }, { children: [_jsx("h3", { children: "1st level" }), _jsx(DroppableContainer, { children: _jsx(StrictModeDroppable, Object.assign({ droppableId: "droppable", direction: "vertical" }, { children: (provided) => (_jsxs(ListWrapper, Object.assign({ ref: provided.innerRef }, provided.droppableProps, { children: [nodes.length === 0 && (_jsx(LevelNotice, { children: "No items added yet" })), nodes.map((n, i) => {
                                            var _a, _b;
                                            return (_jsx(Draggable, Object.assign({ draggableId: ((_a = n.labels[0]) === null || _a === void 0 ? void 0 : _a.value) + i, index: i }, { children: (provided, snapshot) => (_jsx(DraggableWrapper, Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { style: getItemStyle(snapshot.isDragging, provided.draggableProps.style), onMouseDown: e => e.currentTarget.focus() }, { children: _jsx(EditingFirstLevelNode, { isInPath: selectedFirstNode !== selectedNode &&
                                                            selectedFirstNode !== null &&
                                                            selectedFirstNode === n, selectNode: (node) => {
                                                            setSelectedNode(node);
                                                            setSelectedFirstNode(node);
                                                            setSelectedSecondLevelNode(null);
                                                            setSelectedThirdLevelNode(null);
                                                            setSelectedFourthLevelNode(null);
                                                        }, isActive: !!selectedFirstNode &&
                                                            (selectedFirstNode === null || selectedFirstNode === void 0 ? void 0 : selectedFirstNode.key) === n.key, updateNode: updateNode, deleteNode: deleteNode, node: n, selectedLanguage: selectedLanguage }, crypto.randomUUID()) }))) }), ((_b = n.labels[0]) === null || _b === void 0 ? void 0 : _b.value) + i));
                                        }), provided.placeholder] }))) })) }), _jsx(AddNewNodeWrapper, { children: _jsx(AddButton, Object.assign({ onClick: addNewNode }, { children: "Add" })) })] })) })), _jsx(EditingModal, { selectedLanguage: selectedLanguage, updateNode: addNode, closeModal: () => setShowModal(false), showModal: showModal }), _jsx(EditingSecondLevel, { setSelectedNode: setSelectedNode, selectedNode: selectedNode, selectedSecondLevelNode: selectedSecondLevelNode, setSelectedSecondLevelNode: (node) => {
                    setSelectedNode(node);
                    setSelectedSecondLevelNode(node);
                    setSelectedThirdLevelNode(null);
                }, updateSecondLevelNodes: updateSecondLevelNodes, node: selectedFirstNode, selectedLanguage: selectedLanguage, isActive: selectedFirstNode, noParentNodeSelected: nodes.length > 0 && !selectedFirstNode }, crypto.randomUUID()), _jsx(EditingThirdLevel, { setSelectedNode: setSelectedNode, selectedNode: selectedNode, selectedThirdLevelNode: selectedThirdLevelNode, setSelectedThirdLevelNode: (node) => {
                    setSelectedNode(node);
                    setSelectedThirdLevelNode(node);
                    setSelectedFourthLevelNode(null);
                }, firstLevelNode: selectedFirstNode, updateThirdLevelNodes: updateThirdLevelNodes, node: selectedSecondLevelNode, selectedLanguage: selectedLanguage, isActive: selectedSecondLevelNode && selectedFirstNode, noParentNodeSelected: (selectedFirstNode === null || selectedFirstNode === void 0 ? void 0 : selectedFirstNode.nodes.length) > 0 && !selectedSecondLevelNode }, crypto.randomUUID()), _jsx(EditingFourthLevel, { setSelectedNode: (node) => {
                    setSelectedNode(node);
                }, selectedNode: selectedNode, selectFourthLevelNode: selectedFourthLevelNode, setSelectFourthLevelNode: (node) => {
                    setSelectedNode(node);
                    setSelectedFourthLevelNode(node);
                }, updateFourthLevelNodes: updateFourthLevelNodes, firstLevelNode: selectedFirstNode, node: selectedThirdLevelNode, selectedLanguage: selectedLanguage, isActive: selectedFirstNode && selectedSecondLevelNode && selectedThirdLevelNode, noParentNodeSelected: selectedFirstNode &&
                    (selectedSecondLevelNode === null || selectedSecondLevelNode === void 0 ? void 0 : selectedSecondLevelNode.nodes.length) > 0 &&
                    !selectedThirdLevelNode }, crypto.randomUUID()), _jsx(Toaster, { toasterId: toasterId, position: "top-end", mountNode: undefined })] })));
};
