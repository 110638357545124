var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "../../../lt-style/lt-style.css";
import * as React from "react";
import { MegaMenuWebpartSection, } from "../MegaMenuWebpart/MegaMenuWebpartSection";
import Header from "../components/header/header";
import Style from "../components/style/style";
import GeneralOptions from "../components/general-options/general-options";
import TopMenu from "../components/TopMenu/TopMenu";
import { ContentMenu } from "../components/TopMenu/ContentMenu/ContentMenu";
import { SaveAndPublish } from "../components/SaveAndPublish/SaveAndPublish";
import { MegaMenuAPIService } from "../util/MegaMenuAPIService/MegaMenuAPIService";
import { Container, Details, DetailsContainer, Editor, GlobalStyle, HeaderContainer, EditorFullHeight, } from "../Nav.styles";
import Translations from "../components/Translations/Translations";
import TenantAndSitesMenu from "../components/TenantAndSitesMenu/TenentSitesMenu";
import { languages } from "../components/TopMenu/TranslationsMenu/ITranslationLanguage";
import { configView, draftStatus, megamenuView, publishedStatus, successCode, } from "../util/constants";
import { useLocation, useParams } from "react-router-dom";
import { useId, Link, Spinner, Toaster, useToastController, Toast, ToastTitle, ToastTrigger, } from "@fluentui/react-components";
import { SettingsModal } from "../MegaMenuWebpart/EditingView/Modals/SettingsModal/SettingsModal";
const MegaMenuOptions = ({ props, }) => {
    var _a, _b;
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const [view, setView] = React.useState(1);
    const [menuType, setMenuType] = React.useState("configuration");
    const [selectedNode, setSelectedNode] = React.useState(null);
    const [selectedMenuIndex, setSeletecMenuIndex] = React.useState(0);
    const [currentTranslationLanguage, setCurrentTranslationLanguage] = React.useState(null);
    const [selectedFirstLevelNode, setSelectedFirstLevelNode] = React.useState(null);
    const [selectedSecondLevelNode, setSelectedSecondLevelNode] = React.useState(null);
    const [selectedThirdLevelNode, setSelectedThirdLevelNode] = React.useState(null);
    const [selectedFourthLevelNode, setSelectedFourthLevelNode] = React.useState(null);
    const [activeGeneralViewNode, setActiveGeneralViewNode] = React.useState(null);
    const [isConfigurationVisible, setIsConfigurationVisible] = React.useState(true);
    const [styleTabEnabled, setStyleTabEnabled] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [settingsOpen, setSettingsOpen] = React.useState(false);
    const [settings, setSettings] = React.useState(null);
    const location = useLocation();
    const pathSegments = location.pathname
        .split("/")
        .filter((segment) => segment);
    const showComponents = pathSegments.length > 3;
    const handleConfigureClick = (index) => {
        setSelectedNode(null);
        setSelectedFirstLevelNode(null);
        setSelectedSecondLevelNode(null);
        setSelectedThirdLevelNode(null);
        setSelectedFourthLevelNode(null);
        setMenuType(megamenuView);
        setIsConfigurationVisible(false);
        setSeletecMenuIndex(index);
        saveMenu(null, true);
        setView(1);
    };
    const spinnerContainerStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
        width: "50vw",
    };
    const handleBackClick = () => {
        setIsConfigurationVisible(true);
        setMenuType("configuration");
        //refresh the content component
        setSelectedNode(null);
        setSelectedFirstLevelNode(null);
        setSelectedSecondLevelNode(null);
        setSelectedThirdLevelNode(null);
        setSelectedFourthLevelNode(null);
    };
    React.useEffect(() => {
        const pathSegments = location.pathname
            .split("/")
            .filter((segment) => segment);
        // Check if the path includes 'megamenu' and has additional segments beyond it
        const megamenuIndex = pathSegments.findIndex((segment) => segment === "megamenu");
        const isMegamenuView = megamenuIndex !== -1 && pathSegments.length > megamenuIndex + 1;
        // Set menuType based on whether it's a megamenu view or configuration view
        if (isMegamenuView) {
            setMenuType("megamenu");
            setIsConfigurationVisible(false);
        }
        else {
            setMenuType("configuration");
            setIsConfigurationVisible(true);
        }
    }, [location.pathname]);
    const startingMenu = {
        generalOptions: {
            useGlobalSearch: false,
            logoUrl: "",
            primaryLanguage: languages[4],
            otherLanguages: [],
            customBreadcrumbEnabled: false,
            customBreadcrumbName: "",
            customBreadcrumbUrl: "",
        },
        siteCollection: [
            {
                siteCollectionId: crypto.randomUUID(),
                menuName: "Tenant Menu",
                siteCollectionUrls: ["All Sites"],
                status: draftStatus,
                nodes: [],
                styles: {
                    firstLevelBackgroundColor: "#ffffff",
                    firstLevelFontColor: "#000000",
                    firstLevelTextFormat: "uppercase",
                    secondLevelTextFormat: "uppercase",
                    thirdLevelTextFormat: "uppercase",
                    menuStyle: "compact",
                    logoUrl: "",
                    logoLinkUrl: "",
                },
            },
        ],
    };
    const onUpdateSiteCollections = (siteCollections, save) => {
        menu.siteCollection = siteCollections;
        if (save !== false) {
            saveMenu(null, true, siteCollections.length - 1);
        }
    };
    const [menu, setMenu] = React.useState(null);
    const userString = sessionStorage.getItem("User");
    const userRegion = sessionStorage.getItem("Region");
    const routeParams = useParams();
    React.useEffect(() => {
        const checkDefaultStyle = (allMenus) => {
            if (!allMenus)
                return;
            const defaultMatch = (megamenu) => megamenu.styles.firstLevelBackgroundColor === "#ffffff" &&
                megamenu.styles.firstLevelFontColor === "#000000" &&
                megamenu.styles.firstLevelTextFormat === "uppercase" &&
                megamenu.styles.secondLevelTextFormat === "uppercase" &&
                megamenu.styles.menuStyle === "compact" &&
                (!megamenu.styles.logoUrl || megamenu.styles.logoUrl === "") &&
                (!megamenu.styles.logoLinkUrl || megamenu.styles.logoLinkUrl === "");
            return allMenus.every(defaultMatch);
        };
        setStyleTabEnabled(!checkDefaultStyle(menu === null || menu === void 0 ? void 0 : menu.siteCollection));
    }, [menu]);
    React.useEffect(() => {
        if (routeParams.menuId) {
            setSeletecMenuIndex(Number(routeParams.menuId));
            setIsConfigurationVisible(false);
            setMenuType("megamenu");
        }
        else {
            setIsConfigurationVisible(true);
        }
        if (routeParams.configTab === "styling") {
            setView(2);
        }
    }, [routeParams.menuId]);
    React.useEffect(() => {
        const user = JSON.parse(userString);
        const region = sessionStorage.getItem("Region");
        if (!userRegion) {
            return;
        }
        setIsLoading(true);
        MegaMenuAPIService.getMenu(user.tenantId, region)
            .then((response) => {
            if (response.status !== successCode) {
                setMenu(startingMenu);
            }
            else {
                response
                    .json()
                    .then((data) => {
                    data != null ? setMenu(data) : setMenu(startingMenu);
                })
                    .catch((error) => {
                    console.log("error", error);
                });
            }
            setIsLoading(false);
        })
            .catch(() => {
            setIsLoading(false);
        });
    }, []);
    function removeLanguage(n, l) {
        n.labels.forEach((t) => {
            if (t.languageCode === l.languageCode) {
                n.labels.splice(n.labels.indexOf(t), 1);
            }
        });
    }
    function removeTranslationsForDeletedLanguage(l, nodes) {
        nodes.forEach((n) => {
            removeLanguage(n, l);
            removeTranslationsForDeletedLanguage(l, n.nodes);
        });
    }
    function updateNode(nodes, newNode) {
        nodes.forEach((n) => {
            const index = nodes.indexOf(selectedNode);
            if (index !== -1) {
                nodes[index] = newNode;
                setSelectedNode(nodes[index]);
            }
            updateNode(n.nodes, newNode);
        });
    }
    function applyDeletion(nodes, nodeToDelete) {
        nodes.forEach((n) => {
            const index = nodes.indexOf(nodeToDelete);
            if (index !== -1) {
                nodes.splice(index, 1);
            }
            applyDeletion(n.nodes, nodeToDelete);
        });
    }
    function deleteNode() {
        const nodeName = selectedNode.labels.filter((t) => t.languageCode === "default")[0].value;
        if (window.confirm("Are you sure you want to delete the node " + nodeName + " ?")) {
            if (selectedNode === selectedFirstLevelNode) {
                setSelectedFirstLevelNode(null);
                setSelectedSecondLevelNode(null);
                setSelectedThirdLevelNode(null);
                setSelectedFourthLevelNode(null);
            }
            if (selectedNode === selectedSecondLevelNode) {
                setSelectedSecondLevelNode(null);
                setSelectedThirdLevelNode(null);
                setSelectedFourthLevelNode(null);
            }
            if (selectedNode === selectedThirdLevelNode) {
                setSelectedThirdLevelNode(null);
                setSelectedFourthLevelNode(null);
            }
            if (selectedNode === selectedFourthLevelNode) {
                setSelectedFourthLevelNode(null);
            }
            setSelectedNode(null);
            applyDeletion(menu.siteCollection[selectedMenuIndex].nodes, selectedNode);
        }
    }
    function updateNodesAndMenu(newNode, oldNode) {
        updateNode(menu.siteCollection[selectedMenuIndex].nodes, newNode);
        if (selectedFirstLevelNode && newNode.key === selectedFirstLevelNode.key) {
            setSelectedFirstLevelNode(newNode);
        }
        if (selectedSecondLevelNode &&
            newNode.key === selectedSecondLevelNode.key) {
            setSelectedSecondLevelNode(newNode);
        }
        if (selectedThirdLevelNode && newNode.key === selectedThirdLevelNode.key) {
            setSelectedThirdLevelNode(newNode);
        }
        if (selectedFourthLevelNode &&
            newNode.key === selectedFourthLevelNode.key) {
            setSelectedFourthLevelNode(newNode);
        }
    }
    function handlePrimaryLanguageChange(newPrimaryLanguage, prevPrimaryLanguage) {
        const updatedMenu = Object.assign({}, menu);
        const languageAlreadyExists = updatedMenu.generalOptions.otherLanguages.find((language) => language.languageCode === newPrimaryLanguage.languageCode);
        updatedMenu.generalOptions.primaryLanguage = newPrimaryLanguage;
        if (!languageAlreadyExists) {
            updateAllLabelsLanguageCodes(updatedMenu.siteCollection[selectedMenuIndex].nodes, newPrimaryLanguage, prevPrimaryLanguage);
            updateNodesNewValues(updatedMenu.siteCollection[selectedMenuIndex].nodes, newPrimaryLanguage, prevPrimaryLanguage);
        }
        else {
            checkAndUpdateLabels(updatedMenu.siteCollection[selectedMenuIndex].nodes, newPrimaryLanguage, prevPrimaryLanguage);
        }
        reorderLabels(updatedMenu.siteCollection[selectedMenuIndex].nodes, newPrimaryLanguage);
        setMenu(updatedMenu);
    }
    function reorderLabels(nodes, newPrimaryLanguage) {
        nodes.forEach((node) => {
            const primaryLanguageIndex = node.labels.findIndex((label) => label && label.languageCode === newPrimaryLanguage.languageCode);
            if (primaryLanguageIndex > 0) {
                const primaryLanguageLabel = node.labels.splice(primaryLanguageIndex, 1)[0];
                node.labels.unshift(primaryLanguageLabel);
            }
            if (node.nodes.length > 0) {
                reorderLabels(node.nodes, newPrimaryLanguage);
            }
        });
    }
    function checkAndUpdateLabels(nodes, newPrimaryLanguage, prevPrimaryLanguage) {
        nodes.forEach((node) => {
            const newLanguageLabel = node.labels.find((label) => label && label.languageCode === newPrimaryLanguage.languageCode);
            if (!newLanguageLabel) {
                const prevPrimaryLabel = node.labels.find((label) => label && label.languageCode === prevPrimaryLanguage.languageCode);
                if (prevPrimaryLabel) {
                    const newLabel = {
                        languageCode: newPrimaryLanguage.languageCode,
                        value: prevPrimaryLabel.value,
                    };
                    node.labels.push(newLabel);
                }
            }
            if (node.nodes.length > 0) {
                checkAndUpdateLabels(node.nodes, newPrimaryLanguage, prevPrimaryLanguage);
            }
        });
    }
    function updateNodesNewValues(nodes, newPrimaryLanguage, prevPrimaryLanguage) {
        nodes.forEach((node) => {
            const defaultLabel = node.labels.find((label) => label && label.languageCode === newPrimaryLanguage.languageCode);
            const fallbackValue = node.labels.find((label) => label && label.languageCode === prevPrimaryLanguage.languageCode);
            if (defaultLabel) {
                const newLabel = {
                    languageCode: prevPrimaryLanguage.languageCode,
                    value: defaultLabel.value == "" ? fallbackValue.value : defaultLabel.value,
                };
                node.labels.push(newLabel);
                if (node.nodes.length > 0) {
                    updateNodesNewValues(node.nodes, newPrimaryLanguage, prevPrimaryLanguage);
                }
            }
        });
    }
    function updateAllLabelsLanguageCodes(nodes, newPrimaryLanguage, prevPrimaryLanguage) {
        nodes.forEach((node) => {
            node.labels.forEach((label) => {
                if (label && label.languageCode === prevPrimaryLanguage.languageCode)
                    label.languageCode = newPrimaryLanguage.languageCode;
            });
            if (node.nodes.length > 0) {
                updateAllLabelsLanguageCodes(node.nodes, newPrimaryLanguage, prevPrimaryLanguage);
            }
        });
    }
    function increaseVersioning(isSave, siteCollection) {
        const currentVersion = siteCollection.version;
        if (isSave) {
            if (!currentVersion) {
                const newVersion = "0.1";
                siteCollection.version = newVersion;
                return siteCollection;
            }
            else {
                const newVersion = (Number(currentVersion) + 0.1).toFixed(1);
                siteCollection.version = newVersion.toString();
                return siteCollection;
            }
        }
        else {
            if (!currentVersion) {
                const newVersion = "1.0";
                siteCollection.version = newVersion;
                return siteCollection;
            }
            else {
                if (currentVersion.includes(".0")) {
                    const newVersionWithDecimal = (Number(currentVersion) + 1).toString() + ".0";
                    siteCollection.version = newVersionWithDecimal;
                    return siteCollection;
                }
                else {
                    const newVersionWithDecimal = Math.ceil(Number(currentVersion)).toString() + ".0";
                    siteCollection.version = newVersionWithDecimal;
                    return siteCollection;
                }
            }
        }
    }
    function saveMenu(generalOptions, avoidIncrease, index) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!index) {
                index = selectedMenuIndex;
            }
            const userString = sessionStorage.getItem("User");
            const user = userString ? JSON.parse(userString) : null;
            const tenantId = user ? user.tenantId : "";
            const currentMenuVersion = yield MegaMenuAPIService.getMenu(tenantId, userRegion);
            const currentMenu = yield currentMenuVersion.json();
            if (currentMenuVersion.status === successCode) {
                if (currentMenu.siteCollection[index] && menu.siteCollection[index]) {
                    const currentVersion = parseFloat(menu.siteCollection[index].version);
                    const savedVersion = parseFloat(currentMenu.siteCollection[index].version);
                    if (currentVersion < savedVersion) {
                        notify("This menu has been modified by another editor, please refresh to get the latest changes.", "warning", false);
                        return;
                    }
                }
            }
            var data = null;
            var newMenu = null;
            if (avoidIncrease) {
                newMenu = Object.assign(Object.assign({}, menu), { generalOptions: generalOptions ? generalOptions : menu.generalOptions });
                const response = yield MegaMenuAPIService.saveMenu(newMenu, sessionStorage.getItem("Region"));
                data = yield response.json();
                if (response.status !== successCode) {
                    return;
                }
            }
            else {
                setIsSaving(true);
                const updatedSiteCollection = increaseVersioning(true, menu.siteCollection[index]);
                updatedSiteCollection.settings = settings;
                currentMenu.siteCollection[index] = updatedSiteCollection;
                newMenu = Object.assign(Object.assign({}, currentMenu), { generalOptions: generalOptions
                        ? generalOptions
                        : currentMenu.generalOptions });
                const response = yield MegaMenuAPIService.saveMenu(newMenu, sessionStorage.getItem("Region"));
                data = yield response.json();
                if (response.status !== successCode) {
                    return;
                }
                setIsSaving(false);
            }
            if (!avoidIncrease) {
                setIsSaving(false);
                if (location.pathname === "/intranet/navigation/megamenu")
                    return;
                notify("Megamenu saved successfully.");
            }
            const menuFromServer = {
                id: data.id,
                created: data.created,
                createdBy: data.createdBy,
                modified: data.modified,
                modifiedBy: data.modifiedBy,
                generalOptions: data.generalOptions,
                siteCollection: newMenu.siteCollection, //TODO: This is applied to overcome the issue from nodes from the ContentEditor.tsx: fix use findIndex ouver indexOf
            };
            setMenu(menuFromServer);
        });
    }
    function publishMenu(settings, avoidIncrease) {
        return __awaiter(this, void 0, void 0, function* () {
            const userString = sessionStorage.getItem("User");
            const user = userString ? JSON.parse(userString) : null;
            const tenantId = user ? user.tenantId : "";
            const currentMenuVersion = yield MegaMenuAPIService.getMenu(tenantId, userRegion);
            if (currentMenuVersion.status === successCode) {
                const currentMenu = yield currentMenuVersion.json();
                if (currentMenu.siteCollection[selectedMenuIndex] &&
                    menu.siteCollection[selectedMenuIndex]) {
                    const currentVersion = parseFloat(menu.siteCollection[selectedMenuIndex].version);
                    const savedVersion = parseFloat(currentMenu.siteCollection[selectedMenuIndex].version);
                    if (currentVersion < savedVersion) {
                        notify("This menu has been modified by another editor, please refresh to get the latest changes.", "warning", true, 10000);
                        return;
                    }
                }
            }
            if (!avoidIncrease && menuType === megamenuView) {
                setIsSaving(true);
                const updatedSiteCollection = increaseVersioning(false, menu.siteCollection[selectedMenuIndex]);
                menu.siteCollection[selectedMenuIndex] = updatedSiteCollection;
            }
            let publishedMenuIndex = menu.siteCollection.findIndex((publishedMenu) => publishedMenu.status === publishedStatus &&
                publishedMenu.siteCollectionId ===
                    menu.siteCollection[selectedMenuIndex].siteCollectionId);
            if (publishedMenuIndex !== -1) {
                menu.siteCollection[publishedMenuIndex] = JSON.parse(JSON.stringify(menu.siteCollection[selectedMenuIndex]));
                menu.siteCollection[publishedMenuIndex].status = publishedStatus;
            }
            else {
                let newMegaMenu = {
                    siteCollectionId: menu.siteCollection[selectedMenuIndex].siteCollectionId,
                    status: publishedStatus,
                    nodes: menu.siteCollection[selectedMenuIndex].nodes,
                    styles: menu.siteCollection[selectedMenuIndex].styles,
                    menuName: menu.siteCollection[selectedMenuIndex].menuName,
                    siteCollectionUrls: menu.siteCollection[selectedMenuIndex].siteCollectionUrls,
                };
                menu.siteCollection.push(newMegaMenu);
            }
            const menuToSend = Object.assign(Object.assign({}, menu), { generalOptions: settings ? settings : menu.generalOptions });
            menuToSend.generalOptions.useMegaMenuStyle = styleTabEnabled;
            const response = yield MegaMenuAPIService.publishMenu(menuToSend, sessionStorage.getItem("Region"));
            const data = yield response.json();
            if (response.status !== successCode) {
                return;
            }
            setIsSaving(false);
            if (!avoidIncrease) {
                setIsSaving(false);
                notify("Megamenu published successfully.");
            }
            const menuFromServer = {
                id: data.id,
                created: data.created,
                createdBy: data.createdBy,
                modified: data.modified,
                modifiedBy: data.modifiedBy,
                generalOptions: data.generalOptions,
                siteCollection: menu.siteCollection,
            };
            setMenu(menuFromServer);
        });
    }
    function revertMenu() {
        return __awaiter(this, void 0, void 0, function* () {
            const user = JSON.parse(userString);
            const userRegion = sessionStorage.getItem("Region");
            MegaMenuAPIService.getMenu(user.tenantId, userRegion).then((response) => {
                response
                    .json()
                    .then((data) => {
                    const menuFromServer = {
                        id: data.id,
                        created: data.created,
                        createdBy: data.createdBy,
                        modified: data.modified,
                        modifiedBy: data.modifiedBy,
                        generalOptions: data.generalOptions,
                        siteCollection: data.siteCollection,
                    };
                    setMenu(menuFromServer);
                    setSelectedNode(null);
                    setSelectedFirstLevelNode(null);
                    setSelectedSecondLevelNode(null);
                    setSelectedThirdLevelNode(null);
                    setSelectedFourthLevelNode(null);
                })
                    .catch((error) => {
                    console.log("error", error);
                    setMenu(startingMenu);
                });
            });
        });
    }
    function getSelectedNode() {
        if (selectedFirstLevelNode) {
            if (selectedSecondLevelNode) {
                if (selectedThirdLevelNode) {
                    if (selectedFourthLevelNode) {
                        return selectedFourthLevelNode;
                    }
                    return selectedThirdLevelNode;
                }
                return selectedSecondLevelNode;
            }
            return selectedFirstLevelNode;
        }
        return null;
    }
    const notify = (message, type = "success", dismissable = false, timeout = 3000) => dispatchToast(_jsx(Toast, { children: _jsx(ToastTitle, Object.assign({ action: dismissable ? (_jsx(ToastTrigger, { children: _jsx(Link, { children: "Dismiss" }) })) : null }, { children: message })) }), { timeout, intent: type });
    const handleDisableStyleTab = () => {
        const defaultStyle = {
            firstLevelBackgroundColor: "#ffffff",
            firstLevelFontColor: "#000000",
            firstLevelTextFormat: "uppercase",
            secondLevelTextFormat: "uppercase",
            thirdLevelTextFormat: "uppercase",
            menuStyle: "compact",
            logoUrl: "",
            logoLinkUrl: "",
        };
        if (window.confirm("Are you sure you want to disable the style tab? Style from Branding will be applied instead. Note that this action cannot be reverted.")) {
            menu.siteCollection.forEach((siteCollection) => {
                siteCollection.styles = Object.assign(Object.assign({}, siteCollection.styles), defaultStyle);
            });
            setStyleTabEnabled(false);
            saveMenu();
            publishMenu();
        }
    };
    const updateSettings = (settingsValues) => {
        setSettings(settingsValues);
        setMenu((menu) => (Object.assign(Object.assign({}, menu), { siteCollection: menu.siteCollection.map((sc, index) => index === selectedMenuIndex ? Object.assign(Object.assign({}, sc), { settings: settingsValues }) : sc) })));
        setSettingsOpen(false);
    };
    return (_jsxs(Container, Object.assign({ className: `menutype-${menuType}` }, { children: [_jsx(GlobalStyle, {}), _jsx(HeaderContainer, { children: _jsx(Header, {}) }), isLoading ? (_jsx(_Fragment, { children: _jsx(EditorFullHeight, { children: _jsx("div", Object.assign({ style: spinnerContainerStyle }, { children: _jsx(Spinner, { size: "medium", label: "We're getting everything ready for you... ", labelPosition: "below" }) })) }) })) : (menu && (_jsxs(_Fragment, { children: [isConfigurationVisible ? (_jsxs(EditorFullHeight, { children: [_jsx(TenantAndSitesMenu, { clientRegion: (_a = props.opsCenter) === null || _a === void 0 ? void 0 : _a.clientRegion, siteCollections: (_b = menu.siteCollection) !== null && _b !== void 0 ? _b : [], onUpdateSiteCollections: onUpdateSiteCollections, onConfigureClick: handleConfigureClick, updateMenu: (menu, index, sitecollectionUpdate) => {
                                    if (!sitecollectionUpdate)
                                        setMenu(menu);
                                    saveMenu(null, false, index);
                                }, megaMenu: menu }), _jsx(GeneralOptions, { menu: menu, matrixEnabled: menu.generalOptions.matrixEnabled, hideSpAppBar: menu.generalOptions.hideSpAppBar, globalSearch: menu.generalOptions.useGlobalSearch, hideSearch: menu.generalOptions.hideSearch, hideSpHeader: menu.generalOptions.hideSpHeader, hideBreadcrumb: menu.generalOptions.hideBreadcrumb, customBreadcrumbEnabled: menu.generalOptions.customBreadcrumbEnabled, customBreadcrumbName: menu.generalOptions.customBreadcrumbName, customBreadcrumbUrl: menu.generalOptions.customBreadcrumbUrl, enableProductIntegration: menu.generalOptions.enableProductIntegration, logoUrl: menu.generalOptions.logoUrl, primaryLanguage: menu.generalOptions.primaryLanguage, otherLanguages: menu.generalOptions.otherLanguages, currentTranslationLanguage: currentTranslationLanguage, onPrimaryLanguageChange: handlePrimaryLanguageChange, updateSettings: (settings, inUseEffect) => {
                                    saveMenu(settings, true);
                                    if (!inUseEffect) {
                                        publishMenu(settings, true);
                                    }
                                }, updateMenu: (newMenu) => {
                                    menu.generalOptions = newMenu.generalOptions;
                                    menu.siteCollection = newMenu.siteCollection;
                                    saveMenu(null, true);
                                }, styleTabEnabled: styleTabEnabled, disableStyleTab: handleDisableStyleTab })] })) : null, menuType !== configView ? (_jsx(_Fragment, { children: _jsx(SaveAndPublish, { siteCollection: menu === null || menu === void 0 ? void 0 : menu.siteCollection[selectedMenuIndex], revertMenu: () => __awaiter(void 0, void 0, void 0, function* () { return yield revertMenu(); }), saveMenu: () => __awaiter(void 0, void 0, void 0, function* () { return yield saveMenu(); }), publishMenu: () => __awaiter(void 0, void 0, void 0, function* () { return yield publishMenu(); }), isLoading: isSaving, settingsOpen: () => setSettingsOpen(!settingsOpen) }) })) : null, !isConfigurationVisible &&
                        view !== 0 &&
                        menuType === megamenuView ? (_jsx(Editor, { children: _jsxs(_Fragment, { children: [styleTabEnabled && (_jsx(TopMenu, { setView: (view) => {
                                        setView(view);
                                    }, activeItem: view, setMenuType: setMenuType, menuType: menuType, isConfigurationVisible: isConfigurationVisible, onConfigureClick: () => handleConfigureClick(selectedMenuIndex), onBackClick: handleBackClick, menuName: menu.siteCollection
                                        ? menu.siteCollection[selectedMenuIndex].menuName
                                        : "undefined" })), _jsx(MegaMenuWebpartSection, { activeGeneralNode: activeGeneralViewNode, setActiveGeneralNode: (n) => {
                                        setActiveGeneralViewNode(n);
                                    }, selectedFirstLevelNode: selectedNode ? selectedFirstLevelNode : null, selectedSecondLevelNode: selectedNode ? selectedSecondLevelNode : null, selectedThirdLevelNode: selectedNode ? selectedThirdLevelNode : null, selectedFourthLevelNode: selectedNode ? selectedFourthLevelNode : null, setSelectedFirstLevelNode: (n) => {
                                        setSelectedFirstLevelNode(n);
                                    }, setSelectedSecondLevelNode: (n) => {
                                        setSelectedSecondLevelNode(n);
                                    }, setSelectedThirdLevelNode: (n) => {
                                        setSelectedThirdLevelNode(n);
                                    }, setSelectedFourthLevelNode: (n) => {
                                        setSelectedFourthLevelNode(n);
                                    }, updateMenu: (menu) => {
                                        setMenu({
                                            id: menu.id,
                                            created: menu.created,
                                            createdBy: menu.createdBy,
                                            modified: menu.modified,
                                            modifiedBy: menu.modifiedBy,
                                            generalOptions: {
                                                matrixEnabled: menu.generalOptions.matrixEnabled,
                                                hideSearch: menu.generalOptions.hideSearch,
                                                hideSpAppBar: menu.generalOptions.hideSpAppBar,
                                                hideSpHeader: menu.generalOptions.hideSpHeader,
                                                hideBreadcrumb: menu.generalOptions.hideBreadcrumb,
                                                useGlobalSearch: menu.generalOptions.useGlobalSearch,
                                                enableProductIntegration: menu.generalOptions.enableProductIntegration,
                                                logoUrl: menu.generalOptions.logoUrl,
                                                primaryLanguage: menu.generalOptions.primaryLanguage,
                                                otherLanguages: menu.generalOptions.otherLanguages,
                                            },
                                            siteCollection: menu.siteCollection,
                                        });
                                    }, menuIndexValue: selectedMenuIndex, view: view, megaMenu: menu, selectedNode: getSelectedNode(), setSelectedNode: (node) => {
                                        setSelectedNode(node);
                                    } }, crypto.randomUUID())] }) })) : null, _jsxs(DetailsContainer, { children: [_jsx(Details, { children: showComponents && view === 1 && menuType === megamenuView ? (_jsx("div", { children: _jsx(ContentMenu, { deleteNode: deleteNode, updateNodesAndMenu: updateNodesAndMenu, node: getSelectedNode(), currentLanguage: menu.generalOptions.primaryLanguage }, crypto.randomUUID()) })) : showComponents &&
                                    view === 2 &&
                                    menuType === megamenuView ? (_jsx(Style, { existingStyles: menu.siteCollection[selectedMenuIndex].styles, updateStyles: (styles) => {
                                        const newStyle = {
                                            firstLevelBackgroundColor: styles.menuLevelsStyles.level1.background,
                                            firstLevelFontColor: styles.menuLevelsStyles.level1.text,
                                            firstLevelTextFormat: styles.menuLevelsStyles.level1.textFormat,
                                            secondLevelTextFormat: styles.menuLevelsStyles.level2.textFormat,
                                            thirdLevelTextFormat: styles.menuLevelsStyles.level3.textFormat,
                                            logoUrl: styles.logoUrl,
                                            logoLinkUrl: styles.logoLinkUrl,
                                            menuStyle: styles.menuStyle,
                                        };
                                        menu.siteCollection[selectedMenuIndex].styles = newStyle;
                                        setMenu({
                                            id: menu.id,
                                            created: menu.created,
                                            createdBy: menu.createdBy,
                                            modified: menu.modified,
                                            modifiedBy: menu.modifiedBy,
                                            generalOptions: {
                                                matrixEnabled: menu.generalOptions.matrixEnabled,
                                                hideSearch: menu.generalOptions.hideSearch,
                                                hideSpAppBar: menu.generalOptions.hideSpAppBar,
                                                hideSpHeader: menu.generalOptions.hideSpHeader,
                                                enableProductIntegration: menu.generalOptions.enableProductIntegration,
                                                hideBreadcrumb: menu.generalOptions.hideBreadcrumb,
                                                useGlobalSearch: menu.generalOptions.useGlobalSearch,
                                                primaryLanguage: menu.generalOptions.primaryLanguage,
                                                otherLanguages: menu.generalOptions.otherLanguages,
                                            },
                                            selectedLanguage: menu.selectedLanguage,
                                            siteCollection: menu.siteCollection,
                                        });
                                    } })) : ("") }), showComponents && view === 1 && menuType === megamenuView ? (_jsx(Translations, { menu: menu, nodes: menu.siteCollection[selectedMenuIndex].nodes, updateNodesAndMenu: updateNodesAndMenu, node: selectedNode, selectedMenuIndex: selectedMenuIndex, updateMenu: (newMenu) => {
                                    menu.generalOptions = newMenu.generalOptions;
                                    menu.siteCollection = newMenu.siteCollection;
                                    saveMenu(null, true);
                                }, currentLanguage: currentTranslationLanguage }, crypto.randomUUID())) : null] })] }))), _jsx(SettingsModal, { showModal: settingsOpen, closeModal: () => {
                    setSettingsOpen(false);
                }, updateSettings: (settingsValue) => updateSettings(settingsValue), settings: (menu === null || menu === void 0 ? void 0 : menu.siteCollection[selectedMenuIndex].settings) || {
                    textFormat: "uppercase",
                    navigationInteraction: "click",
                } }), _jsx(Toaster, { toasterId: toasterId, position: "top-end", mountNode: undefined })] })));
};
export default MegaMenuOptions;
